export default {
  namespaced: true,
  state: {
    driver: {
      contragent: {},
      passport: {},
      driverslicense: {},
    },
  },
  getters: {
    getData(state) {
      return state.driver;
    },

    getID(state) {
      return state.driver ? state.driver.id : 0;
    },
  },
  mutations: {
    SET(state, driver) {
      state.driver = driver;
    },

    CLEAR(state) {
      state.driver = undefined;
    },
  },
  actions: {
    set({ commit }, driver) {
      commit("SET", driver);
    },

    clear({ commit }) {
      commit("CLEAR");
    },
  },
};
