// contragetns
export default function (instance) {
  return {
    async getFormtypes(filter) {
      return await instance.get(
        "contragents/formtypes?filter=" + JSON.stringify(filter)
      );
    },

    async formtypesInvertStatus(id) {
      return await instance.post("contragents/formtypes/invert/" + id);
    },

    async updateFormtypes(data) {
      return await instance.post("contragents/formtypes/update", data);
    },

    async getContagents(filter) {
      return await instance.get("contragents/list/" + filter);
    },

    async getDirectorPositions() {
      return await instance.get("contragents/director_positions");
    },

    async getContragent(contragentID) {
      return await instance.get("contragents/" + contragentID);
    },

    async updateContragent(data) {
      return await instance.post("contragents/update", data);
    },

    async invertStatus(id) {
      return await instance.post("contragents/status/invert/" + id);
    },

    async getBankDetails(contragentID) {
      return await instance.get("contragents/bankdetails/list/" + contragentID);
    },

    async invertBankStatus(id) {
      return await instance.post("contragents/bankdetails/invert/" + id);
    },

    async updateBankDetails(data) {
      return await instance.post("contragents/bankdetails/update", data);
    },

    async getContactTypes() {
      return await instance.get("contragents/contacts/types");
    },

    async getUpdateContact(data) {
      return await instance.post("contragents/contacts/update", data);
    },

    async getContacts(contragentID) {
      return await instance.get("contragents/contacts/list/" + contragentID);
    },

    async invertContactStatus(id) {
      return await instance.post("contragents/contact/invert/" + id);
    },

    async getPassportDetails(contragentID) {
      return await instance.get(
        "contragents/passportdetails/list/" + contragentID
      );
    },

    async updatePassportDetails(data) {
      return await instance.post("contragents/passportdetails/update", data);
    },

    async updateEmploymentHistory(data) {
      return await instance.post("contragents/employmenthistory/update", data);
    },

    async getEmploymentHistory(contragentID) {
      return await instance.get(
        "contragents/employmenthistory/list/" + contragentID
      );
    },

    async searchCustomer(filter) {
      return await instance.get(
        "contragents/search/customer/?filter=" + filter
      );
    },

    async searchContractor(filter) {
      return await instance.get(
        "contragents/search/contractor/?filter=" + filter
      );
    },

    async getOurCompanies() {
      return await instance.get("contragents/ourcompanies");
    },

    async searchAll(filter) {
      return await instance.get("contragents/search/all/?filter=" + filter);
    },

    async searchINN(filter) {
      return await instance.get("contragents/search/inn/" + filter);
    },

    async getDriversLicenses(contragentID) {
      return await instance.get(
        "contragents/driverslicense/list/" + contragentID
      );
    },
    // async getDriversLicenseDetails(licenseID) {
    //   return await instance.get(
    //     "contragents/driverslicense/list/" + contragentID
    //   );
    // },

    async updateDriversLicenseDetails(data) {
      return await instance.post("contragents/driverslicense/update", data);
    },

    async getDriversLicenseTypes() {
      return await instance.get("contragents/driverslicense/types");
    },
  };
}
