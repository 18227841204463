export default function (instance) {
  return {
    async getList(contragentID, filter) {
      return await instance.get(
        `drivers/list/${contragentID}?filter=${JSON.stringify(filter)}`
      );
    },

    async getDriver(driverID) {
      return await instance.get("drivers/" + driverID);
    },

    async getDriverLicensesCategories() {
      return await instance.get("drivers/licenses/categories");
    },

    async create(data) {
      return await instance.post("drivers/create", data);
    },

    async check(data) {
      return await instance.post("drivers/check", data);
    },

    async createFullData(data) {
      return await instance.post("drivers/create/fulldata", data);
    },

    async updateFullData(data) {
      console.log("data", data);
      return await instance.post("drivers/update/fulldata", data);
    },

    async invertStatus(id) {
      return await instance.post("drivers/invertstatus/" + id);
    },

    async searchPassport(data) {
      return await instance.get(
        "drivers/searchpassport?data=" + JSON.stringify(data)
      );
    },

    async searchDriversLicense(data) {
      return await instance.get(
        "drivers/searchdriverslicense?data=" + JSON.stringify(data)
      );
    },
  };
}
