export default function (instance) {
  return {
    async getList() {
      return await instance.get("units/list");
    },

    async create(data) {
      return await instance.post("units/create", data);
    },

    async update(id, data) {
      return await instance.post("units/update/" + id, data);
    },
  };
}
