export default function (instance) {
  return {
    async update(data) {
      return await instance.post("1c/update", data);
    },

    async getData() {
      return await instance.get("1c/data");
    },

    async updateInvoice(data) {
      return await instance.post("1c/invoice/update", data);
    },

    async uploadInvoice() {
      return await instance.get("1c/invoices");
    },

    async invertInvoiceUpdate(id) {
      return await instance.post("1c/invoice/update/invert/" + id);
    },

    async changeInvoiceAction(id) {
      return await instance.post("1c/invoice/change/status/" + id);
    },

    async getInvoiceList() {
      return await instance.get("1c/invoice/list");
    },

    async getcompanyList() {
      return await instance.get("1c/companies");
    },

    async getUPDList() {
      return await instance.get("1c/upd/list");
    },

    async updateUPD(data) {
      return await instance.post("1c/upd/update", data);
    },

    async uploadUPD() {
      return await instance.get("1c/utdocs");
    },

    async invertUPDUpdate(id) {
      return await instance.post("1c/upd/update/invert/" + id);
    },

    async changeUPDAction(id) {
      return await instance.post("1c/upd/change/status/" + id);
    },

    async getOrderList() {
      return await instance.get("1c/order/list");
    },

    async updateAct(data) {
      return await instance.post("1c/act/update", data);
    },

    async getActsList() {
      return await instance.get("1c/act/list");
    },

    async invertActUpdate(id) {
      return await instance.post("1c/act/update/invert/" + id);
    },

    async changeActAction(id) {
      return await instance.post("1c/act/change/status/" + id);
    },

    async uploadActs() {
      return await instance.get("1c/acts");
    },

    async updateContract(data) {
      return await instance.post("1c/contract/update", data);
    },

    async getContractsList() {
      return await instance.get("1c/contract/list");
    },

    async invertContractUpdate(id) {
      return await instance.post("1c/contract/update/invert/" + id);
    },

    async changeContractAction(id) {
      return await instance.post("1c/contract/change/status/" + id);
    },

    async uploadcontracts() {
      return await instance.get("1c/contracts");
    },

    async updateSF(data) {
      return await instance.post("1c/sf/update", data);
    },

    async getSFList() {
      return await instance.get("1c/sf/list");
    },

    async invertSFUpdate(id) {
      return await instance.post("1c/sf/update/invert/" + id);
    },

    async changeSFAction(id) {
      return await instance.post("1c/sf/change/status/" + id);
    },

    async uploadSF() {
      return await instance.get("1c/sfs");
    },
  };
}
