export default {
  namespaced: true,
  state: {
    account: undefined,
  },
  getters: {
    isAuthenticated(state) {
      return state.account ? true : false;
    },

    isSuper(state) {
      return state.account && state.account.roles2.isSuper ? true : false;
    },
    isAdmin(state) {
      return state.account && state.account.roles2.isAdmin ? true : false;
    },
    isRequester(state) {
      return state.account && state.account.roles2.isRequester ? true : false;
    },
    isLogist(state) {
      return state.account && state.account.roles2.isLogist ? true : false;
    },
    is1C(state) {
      return state.account && state.account.roles2.is1C ? true : false;
    },

    getUserName(state) {
      return state.account
        ? `${state.account.name1} ${state.account.name2} ${state.account.name3}`
        : "";
    },

    getID(state) {
      return state.account ? state.account.id : "0";
    },
  },
  mutations: {
    SETACCUNT(state, account) {
      state.account = account;
    },

    SIGNOUT(state) {
      state.account = undefined;
    },
  },
  actions: {
    setAccount({ commit }, account) {
      commit("SETACCUNT", account);
    },

    signout({ commit }) {
      commit("SIGNOUT");
    },
  },
};
