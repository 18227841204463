export default function (instance) {
  return {
    async getTransportDepartment() {
      return await instance.get("company/department/transport");
    },

    async getLogistQuenue(departmentID) {
      return await instance.get("company/logist/quenue/" + departmentID);
    },

    async setLogistQuenue(departmentID, data) {
      return await instance.post("company/logist/quenue/" + departmentID, data);
    },

    async createPosition(data) {
      return await instance.post("company/position/create", data);
    },

    async updatePosition(data) {
      return await instance.post("company/position/update", data);
    },

    async getPosition(filter) {
      return await instance.get("company/position/list/" + filter);
    },

    async getPositionsByDepartment(DepartmentID) {
      return await instance.get("company/position/department/" + DepartmentID);
    },

    async getManagersByDepartment(DepartmentID) {
      return await instance.get("company/managers/department/" + DepartmentID);
    },

    async getDepartments(filter) {
      return await instance.get("company/department/list/" + filter);
    },

    async createDepartment(data) {
      return await instance.post("company/department/create", data);
    },

    async updateDepartment(data) {
      return await instance.post("company/department/update", data);
    },

    async orderDepartmentUp(id) {
      return await instance.post("company/department/orderUp/" + id);
    },
    async orderDepartmentDown(id) {
      return await instance.post("company/department/orderDown/" + id);
    },

    async orderPositionUp(id) {
      return await instance.post("company/position/orderUp/" + id);
    },
    async orderPositionDown(id) {
      return await instance.post("company/position/orderDown/" + id);
    },

    async post(url, data) {
      let responseR;
      await instance
        .post(url, data)
        .then((response) => (responseR = response.data))
        .catch((error) => {
          console.log("error", error);
          if (error.response) {
            responseR = error.response.data
              ? error.response.data
              : error.response;
          } else {
            responseR = { data: { error: "NETWORK ERROR" } };
          }
        });
      return responseR;
    },

    async get(url) {
      let responseR = {};
      await instance
        .get(url)
        .then((response) => {
          responseR = response.data;
        })
        .catch((error) => {
          console.log(error);
          responseR.error = "NETWORK ERROR";
          if (error.response) {
            responseR = error.response.data
              ? error.response.data
              : error.response;
          }
        });
      return responseR;
    },
  };
}
