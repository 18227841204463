export default function (instance) {
  return {
    async getTaxes() {
      return await instance.get("finance/taxes");
    },

    async getPaymentsByOrder(orderID) {
      return await instance.get("finance/payments/order/" + orderID);
    },

    async createPayment(data) {
      return await instance.post("finance/payments/create", data);
    },

    async updatePayment(data) {
      return await instance.post("finance/payments/update", data);
    },

    async getPaymentShemes(filter) {
      return await instance.get("finance/paymentshemes/" + filter);
    },

    async createPaymentShemes(data) {
      return await instance.post("finance/paymentshemes/create", data);
    },

    async updatePaymentShemes(data) {
      return await instance.post("finance/paymentshemes/update", data);
    },

    async invertStatusPaymentShemes(id) {
      return await instance.post("finance/paymentshemes/invertstatus/" + id);
    },
  };
}
