export default {
  namespaced: true,
  state: {
    truck: {},
  },
  getters: {
    getData(state) {
      return state.truck;
    },

    getID(state) {
      return state.truck ? state.truck.id : 0;
    },
  },
  mutations: {
    SET(state, truck) {
      state.truck = truck;
    },

    CLEAR(state) {
      state.truck = undefined;
    },
  },
  actions: {
    set({ commit }, truck) {
      commit("SET", truck);
    },

    clear({ commit }) {
      commit("CLEAR");
    },
  },
};
