export default {
  namespaced: true,
  state: {
    taxes: undefined,
  },
  getters: {
    getTaxes(state) {
      return state.taxes ? state.taxes : [];
    },
  },
  mutations: {
    SET(state, taxes) {
      state.taxes = taxes;
    },

    CLEAR(state) {
      state.truck = undefined;
    },
  },
  actions: {
    set({ commit }, taxes) {
      commit("SET", taxes);
    },
  },
};
