export default function (instance) {
  return {
    async sendFiles(module, sourceID, formData, callback) {
      return await instance.postFiles(
        `files/upload/${module}/` + sourceID,
        formData,
        callback
      );
    },

    async getFiles(module, sourceID) {
      return await instance.get(`files/list/${module}/` + sourceID);
    },

    async getTypes() {
      return await instance.get(`files/types`);
    },

    async deleteFiles(module, sourceID, FID) {
      return await instance.delete(`files/delete/${module}/${sourceID}/${FID}`);
    },

    getFileURL(file) {
      return (
        instance.baseURL + `files/download/${file.fid}/${file.downloadFilename}`
      );
      //return instance.baseURL+`files/download/${file.fid}`
    },
  };
}
