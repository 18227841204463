export default function (instance) {
  return {
    async getNomenclature() {
      return await instance.get("storage/nomenclature/list");
    },

    async createNomenclature(data) {
      return await instance.post("storage/nomenclature/create", data);
    },

    async updateNomenclature(id, data) {
      return await instance.post("storage/nomenclature/update/" + id, data);
    },

    async getList() {
      return await instance.get("storage/list");
    },

    async update(id, data) {
      return await instance.post("storage/update/" + id, data);
    },
  };
}
