<template>
    <div class="widget-wrapper">
      <div><h2>Запросы. Общая статистика&nbsp;</h2></div>
      <div>
        <div class="card">
          <div>
            <table>
              <thead>
                <th>Стутус</th>
                <th>Сегодня</th>
                <th>вчера</th>
                <th>с ПН</th>
                <th>пр.неделя</th>
                <th>с 1-го</th>
                <th>пр.месяц</th>
              </thead>
              <tbody>
                <tr v-for="state in requestStatData.states" :key="state.id">
                  <td>
                    <div
                      :class="{'state-new-400' : state.id==1, 'state-warning-400' : state.id==2,
                               'state-error-400' : state.id==3,  'state-error' : state.id==5,
                               'state-total' : state.id==-1,}"
                    >
                      {{ state.title }}
                    </div>
                  </td>
                  <td :class="{'state-total' : state.id==-1}">{{ state.today }}</td>
                  <td :class="{'state-total' : state.id==-1}">{{ state.yesterday }}</td>
                  <td :class="{'state-total' : state.id==-1}">{{ state.week }}</td>
                  <td :class="{'state-total' : state.id==-1}">{{ state.lastweek }}</td>
                  <td :class="{'state-total' : state.id==-1}">{{ state.month }}</td>
                  <td :class="{'state-total' : state.id==-1}">{{ state.lastmonth }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { utf8_to_b64 } from '@/assets/js/base64.js';
export default {
  name: 'WidgetRequestsStatistic',
  data: () => ({
    requestStatData : {
      'states' : []
    },
    updateTimeout : null,
  }),

  mounted () {
    this.loadRequestStatData()
    this.updateTimeout = setInterval(this.updateState, 60000);
  },

  unmounted () {
    if (this.updateTimeout) {
      clearInterval(this.updateTimeout)
    }
  },

  methods : {
    updateState() {
      this.loadRequestStatData()
    },

    async loadRequestStatData() {
      let filter = utf8_to_b64(JSON.stringify({}))
      let result = await this.$api.order.getRequestsStatisticByFilter(filter)
      if (result.statistic) {
        this.requestStatData = result.statistic

      }
    }
  }
}
</script>

<style>

</style>