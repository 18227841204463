export default {
  namespaced: true,
  state: {
    contragent: {},
  },
  getters: {
    getData(state) {
      return state.contragent;
    },

    getID(state) {
      return state.contragent ? state.contragent.id : 0;
    },
  },
  mutations: {
    SET(state, contragent) {
      state.contragent = contragent;
    },

    CLEAR(state) {
      state.contragent = undefined;
    },
  },
  actions: {
    set({ commit }, contragent) {
      commit("SET", contragent);
    },

    clear({ commit }) {
      commit("CLEAR");
    },
  },
};
