export default function (instance) {
  return {
    async getAdditional() {
      return await instance.get("service/additional");
    },

    async createAdditional(data) {
      return await instance.post("service/additional/create", data);
    },

    async updateAdditional(serviceID, data) {
      return await instance.post(
        "service/additional/update/" + serviceID,
        data
      );
    },
  };
}
