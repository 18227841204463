export default function (instance) {
  return {
    async getOrderCustomer(id) {
      return await instance.get("reports/order/customer/" + id);
    },

    async getOrderExecutor(id) {
      return await instance.get("reports/order/executor/" + id);
    },
    async getOrderDriverTask(filter) {
      return await instance.get(
        "reports/order/drivertask?filter=" + JSON.stringify(filter)
      );
    },
  };
}
