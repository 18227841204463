export default function (instance) {
  return {
    async getList(id, filter, filterSelect) {
      return await instance.get(
        `contracts/list/${id}?filter=${filter}&filterSelect=${filterSelect}`
      );
    },

    async create(data) {
      return await instance.post("contracts/create", data);
    },

    async invertStatus(id) {
      return await instance.post("contracts/invertstatus/" + id);
    },

    async update(id, data) {
      return await instance.post("contracts/update/" + id, data);
    },
  };
}
