<template>
  <div class="fullscreen div-center">
    <div class="signin-wrapper">
      <div class="signin-logo"><img src="img/logo.png" alt="Грузоперевозки по Москве"></div>
      <div class="signin-title">ВХОД В СИСТЕМУ</div>
      <div class="signin-label">Логин</div>
      <div class="signin-input"><input type="text" v-model.trim="value.login"></div>
      <div class="signin-label">Пароль</div>
      <div class="signin-input"><input type="password" v-model.trim="value.password"></div>
      <div class="signin-buttom">
        <button class="bg-prime-prime-blue" @click="submit()">Войти</button>
      </div>
      <div style="margin-top: 20px;" v-show="development">
        <select v-model="testUserIndex" @click="selectTestUser()">
        <option value="-1"></option>
        <option 
          v-for="(item, index) in testUsers" :key="item.id"
          :value="index">{{ item.title }} </option>
      </select>
      </div>
    </div>
  </div>
</template>

<script>
import { utf8_to_b64 } from '@/assets/js/base64.js';

export default {
  name: 'HomeView',

  data: () => ({
    value : {},


    testUserIndex: -1,
    testUsers: [
      {id:0, title: 'Собственник', login: 'userS', password: '1'},
      {id:1, title: 'Специалист по запросам', login: 'disp', password: '1'},
      {id:2, title: 'Логист Экспедиция  Иванов 1', login: 'user1', password: '1'},
      {id:3, title: 'Логист Экспедиция 22', login: 'user32', password: '1'},
      {id:4, title: 'Логист Экспедиция 34', login: 'andrew.puchkov@gmail.com', password: '1'},
      {id:5, title: 'Логист АТП 22', login: 'user11', password: '1'},
      {id:6, title: 'Логист АТП 33', login: 'user23', password: '1'},
      {id:7, title: 'Логист АТП Петров 2 Петр Петрович', login: 'user2', password: '1'},
      {id:8, title: 'Администратор', login: 'admin', password: '1'},
      {id:9, title: 'Кадровик', login: 'userK', password: '1'},
    ],

    development : false
  }),

  created () {
    this.development = process.env.NODE_ENV=='development'
  },

  methods: {
    selectTestUser () {
        if (this.testUserIndex > -1) {
          this.value.login = this.testUsers[this.testUserIndex].login
          this.value.password = this.testUsers[this.testUserIndex].password
        }
    },

    async submit() {
      const payload = {
        login: utf8_to_b64(this.value.login),
        password: utf8_to_b64(this.value.password)
      }
      const result = await this.$api.auth.signIn(payload)
      if (result.jwt) {
        this.$store.dispatch('account/setAccount', result)
        this.$api.auth.setJWT(result.jwt)
        // сохраняем токен в куках
        this.$api.cookie.setCookie('jwt', result.jwt, 7)
        if (this.$route.query.path) {
          this.$router.push(this.$route.query.path)
          return
        }
        this.$router.push('/dashboard')
        return
      }

      if (result.response) {
        if ((result.response.status)&&(result.response.status == 401)) {
          this.$api.eventBus.emit('toast', {text: 'Логин или пароль неверны!', style: 'error'})
          return
        }
      }
      this.$api.eventBus.emit('toast', {text: 'Ошибка сети!', style: 'error'})

    }
  }
}
</script>
