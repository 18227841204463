<template>
    <div class="dashbord">
      <div class="widget half" v-if="isSuper||isRequester||isLogist">
        <widget-requests-list />
      </div>

      <div class="widget half" v-if="isSuper">
        <widget-requests-statistic />
      </div>

      <div class="half" >
        <!-- Пустое место, что-бы сдвинуть блок влево -->
      </div>

      <div class="widget half" v-if="isSuper">
          <widjet-request-promo-statictic />
      </div>
    </div>
</template>

<script>
import store from '@/store'
import WidgetRequestsList from '@/components/widgets/orders/WidgetRequestsList.vue'
import WidgetRequestsStatistic from '@/components/widgets/orders/WidgetRequestsStatistic.vue'
import WidjetRequestPromoStatictic from '@/components/widgets/orders/WidjetRequestPromoStatictic.vue'
export default {
  name: 'DashboardView',
  components : {
    WidgetRequestsList, WidgetRequestsStatistic, WidjetRequestPromoStatictic
  },
  computed: {
    isSuper () {
      return store.getters['account/isSuper']
    },
    isAdmin() {
      return store.getters['account/isAdmin']
    },
    isRequester() {
      return store.getters['account/isRequester']
    },
    isLogist() {
      return store.getters['account/isLogist']
    },
  },


}
</script>

<style>

</style>