export default function (instance) {
  return {
    async getTypes() {
      return await instance.get("cars/types");
    },

    async createType(data) {
      return await instance.post("cars/types/create", data);
    },

    async updateType(typeID, data) {
      return await instance.post("cars/types/update/" + typeID, data);
    },

    async getTrucksList(contragentID, filter) {
      return await instance.get(
        `cars/list/${contragentID}?filter=${JSON.stringify(filter)}`
      );
    },

    async createTruck(data) {
      return await instance.post(`cars/create`, data);
    },

    async updateTruck(data) {
      return await instance.post(`cars/update`, data);
    },

    async invertStatus(id) {
      return await instance.post("cars/invertstatus/" + id);
    },

    async invertContragentsCarsStatus(id) {
      return await instance.post("cars/invertcontragentscarsstatus/" + id);
    },

    async searchCar(data) {
      return await instance.get("cars/search?data=" + JSON.stringify(data));
    },

    async searchLikeByNumber(data) {
      return await instance.get(
        "cars/search/like?data=" + JSON.stringify(data)
      );
    },

    async getOurTrucks() {
      return await instance.get("cars/ourtrucks");
    },

    async createOurTruck(data) {
      return await instance.post("cars/ourtrucks/create", data);
    },

    async getTruck(truckID) {
      return await instance.get("cars/general/" + truckID);
    },

    async updateTruckGeneral(data) {
      return await instance.post("cars/general/update", data);
    },
  };
}
