<template>
  <side-bar/>
  <div class="container">
    <router-view />
  </div>
  <notify-icon/>
</template>

<script>
import SideBar from "@/components/app/SideBar"
import NotifyIcon from '@/components/widgets/notify/NotifyIcon.vue'


export default {
  name : 'main-layout',
  components : {
    SideBar, NotifyIcon
  },

  data: () => ({
    leftMenu : []
  }),

  async mounted() {
    //this.leftMenu = await this.$api.auth.getMenu()
  }

}
</script>

