export default function (instance) {
  return {
    async create(data) {
      return await instance.post("address/create", data);
    },

    async search(searchFilter) {
      return await instance.get("address/search/" + searchFilter);
    },
  };
}
