export default function (instance) {
  return {
    async getCategories() {
      return await instance.get("cargo/categoties");
    },

    async createCategories(data) {
      return await instance.post("cargo/categoties/create", data);
    },

    async updateCategories(cargoCategoryID, data) {
      return await instance.post(
        "cargo/categoties/update/" + cargoCategoryID,
        data
      );
    },
  };
}
