export default {
  namespaced: true,
  state: {
    order: undefined,
    orderPayments: {
      list: [],
    },
  },
  getters: {
    getID(state) {
      return state.order ? state.order.id : 0;
    },

    getOrder(state) {
      return state.order
        ? state.order
        : {
            id: 0,
            number: "",
            departmentID: 3,
            logistID: 0,
            customerID: 0,
            customer: {
              id: 0,
              name1: "",
            },
            content: "",
            price: "",
            stateID: 0,
          };
    },

    getOrderNumber(state) {
      return state.order ? state.order.number : "";
    },

    getPayments_customer(state) {
      return state.orderPayments.list.filter(
        (item) => item.module == "order__customer"
      );
    },

    getPayments_contractor(state) {
      return state.orderPayments.list.filter(
        (item) => item.module == "order__contractor"
      );
    },
  },
  mutations: {
    SET(state, order) {
      state.order = order;
    },
    SETCUSTOMER(state, newCustomer) {
      state.order.customer = newCustomer;
      state.order.customerID = newCustomer.id;
    },
    SETPAYMENTS(state, newPayments) {
      state.orderPayments = newPayments;
    },
    UPDATEPAYMENT(state, payment) {
      const index = state.orderPayments.list.findIndex(
        (item) => item.id == payment.id
      );
      if (index > -1) {
        state.orderPayments.list[index] = payment;
      }
    },

    CLEAR(state) {
      state.order = undefined;
    },
  },
  actions: {
    set({ commit }, order) {
      commit("SET", order);
    },
    setCustomer({ commit }, newCustomer) {
      commit("SETCUSTOMER", newCustomer);
    },

    setPayments({ commit }, newPayments) {
      commit("SETPAYMENTS", newPayments);
    },

    updatePayment({ commit }, payment) {
      commit("UPDATEPAYMENT", payment);
    },

    clear({ commit }) {
      commit("CLEAR");
    },
  },
};
