export default function (instance) {
  return {
    // baseURL : "http://suggestions.dadata.ru/suggestions/api/4_1/rs",
    // token : "0c89686ce585b307037758955055cb1b81e02c0e",

    async getBankByBIK(bik) {
      return await instance.get("external/bankbyBIK/" + bik);
      // var options = {
      //   method: "POST",
      //   mode: "cors",
      //   headers: {
      //       "Content-Type": "application/json",
      //       "Accept": "application/json",
      //       "Authorization": "Token " + this.token
      //   },
      //   body: JSON.stringify({query: bik})
      // }
      // var url = this.baseURL + '/findById/bank'

      // var response = null
      // await fetch(url, options)
      // .then(response => response.text())
      // .then(result => response = JSON.parse(result))
      // .catch(() => this.eventBus.emit('network-error', 'ошибка подключения к сервису daData '));

      // return response
    },
  };
}
