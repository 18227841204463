import { createStore } from "vuex";
import account from "./account";
import order from "./order";
import truck from "./truck";
import finance from "./finance";
import driver from "./driver";
import contragent from "./contragent";

export default createStore({
  state: {
    cfg: {},
  },
  getters: {
    getCfg(state) {
      return state.cfg ? state.cfg : {};
    },
  },
  mutations: {
    SET(state, cfg) {
      state.cfg = cfg;
    },
  },
  actions: {
    setCfg({ commit }, cfg) {
      commit("SET", cfg);
    },
  },
  modules: {
    account,
    order,
    truck,
    finance,
    driver,
    contragent,
  },
});
