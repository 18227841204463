<template>
  <div class="widget-wrapper">
    <div>
      <h2>
        Текущие запросы &nbsp;<router-link to="/requests">&equiv;</router-link>
      </h2>
    </div>
    <div>
      <div class="card widget__card">
        <div>
          <table>
            <thead>
              <th>#</th>
              <th>Дата</th>
              <th>Время</th>
              <th>Логист</th>
              <th>Отдел</th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr
                v-for="item in requestActive"
                :key="item.id"
                @click="this.$router.push('request/' + item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.logist }}</td>
                <td>{{ item.department }}</td>
                <td>
                  <div
                    :class="{
                      'state-new': item.state == 1,
                      'state-error-400': item.state == 3,
                      'state-error': item.state == 5,
                    }"
                  >
                    {{ item.stateTitle }}
                  </div>
                </td>
                <td @click.stop="">
                  <a :href="'..?path=request/' + item.id" target="blank"
                    ><i class="fa fa-link"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utf8_to_b64 } from "@/assets/js/base64.js";
export default {
  name: "WidgetRequestsList",

  data: () => ({
    requestActive: [],
    updateTimeout: null,
  }),

  mounted() {
    this.loadRequest();
    this.updateTimeout = setInterval(this.updateState, 60000);
  },

  unmounted() {
    if (this.updateTimeout) {
      clearInterval(this.updateTimeout);
    }
  },

  methods: {
    updateState() {
      this.loadRequest();
    },

    async loadRequest() {
      let filter = utf8_to_b64(JSON.stringify({ mode: "active" }));
      let result = await this.$api.order.getRequests(filter);
      if (result.list) {
        this.requestActive = result.list;
      }
    },
  },
};
</script>

<style></style>
