<template>
  <div id="element-to-convert" v-show="false">
    <div class="text">Это тест1</div>
    <img src="img/logo-bar.png" alt="" />
    <table style="width: 400px">
      <thead>
        <tr>
          <th>Номер</th>
          <th>Наименование</th>
          <th>Описание</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Первый</td>
          <td>Первый Элемет</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Второй</td>
          <td>Второй Элемет</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="button" style="width: 200px" v-show="false">
    <button class="white bg-prime-prime-blue" @click="exportToPDF">
      Export to PDF
    </button>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "TestView",

  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "i-was-html.pdf",
      });
    },
  },
};
</script>

<style></style>
